import { onMounted } from "vue";

export const useFonts = () => {
    onMounted(() => {
        const fonts = [
            "https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap",
            "https://fonts.googleapis.com/css2?family=Roboto:wght@1,300;1,400;1,500;1,700&display=swap",
            "https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap"
        ]

        fonts.forEach((fontUrl) => {
            const fontLink = document.createElement("link");
            fontLink.href = fontUrl;
            fontLink.rel = "stylesheet";
            document.head.appendChild(fontLink);
        })
    })
}