<template>
  <div class="api-section api-widget save-card">
    <h1 class="api-h1">{{ $t('saving_cards_payment_page') }}</h1>
    <p class="api-widget__description">
      {{ $t('enable_card_saving_func') }}
    </p>
    <div class="api-schema">
      <div class="schema">
        <h5 class="schema__header">
         {{ $t('req_body_schema') }}:
          <span>application/json</span>
        </h5>
        <table description="save card" class="schema__table table-thead-hidden">
          <caption>
            Schema table
          </caption>
          <thead>
            <th>name</th>
            <th>description</th>
          </thead>
          <tbody>
            <tr>
              <td class="td-first">
                <span class="arrow"></span>
                <span class="property-name"> user_id: </span>
                <span class="required"> required </span>
              </td>
              <td class="td-second">
                <div>
                  <div>
                    <span class="type"> string. </span>
                  </div>
                  <div>
                    <p class="description">
                      {{ $t('id_client_saved_cards') }}
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss">
.save-card {
  border-top: 0.8px solid rgb(115, 115, 115);
  border-bottom: 0.8px solid rgb(115, 115, 115);
  .api-h1 {
    max-width: 100%;
  }
  &-suptitle {
    border-top: 1px solid rgb(115, 115, 115);
    border-bottom: 1px solid rgb(115, 115, 115);
    display: block;
    padding: 24px 0;
    width: max-content;
  }
}

.api-widget {
  width: 60%;
  padding-bottom: 40px;
  .api-h1 {
    margin-bottom: 0;
  }
  &__description {
    margin-top: 0;
    padding: 0 40px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.4em;
    font-size: 15.5px;
    color: rgb(51, 51, 51);
  }
  .api-title {
    margin-top: 50px;
  }
  &__code {
    margin: 20px 40px;
    & > pre {
      padding-bottom: 0;
    }
  }
  &__screen {
    width: 100%;
    padding: 20px 40px 0;
    object-fit: contain;
    object-position: center center;
  }
  &__demo {
    padding: 40px 40px 0px;
    display: flex;
    align-items: center;
    span {
      margin-right: 15px;
      font-family: Montserrat, sans-serif;
      font-weight: 500;
      font-size: 22px;
      color: rgb(51, 51, 51);
    }
    button {
      border: 1.5px solid #19226d;
      background: transparent;
      color: #19226d;
      font-size: 14px;
      font-weight: 500;
      border-radius: 4px;
      height: 34px;
      padding: 0 20px;
      cursor: pointer;
      transition: 0.25s;
      &:hover {
        background: #19226d;
        color: white;
      }
    }
  }
}

.onevision-widget {
  z-index: 1111111111;
}

.token-code {
  padding: 0 40px;
}

@media (max-width: 939px) {
  .api-widget {
    width: 100%;
  }
}
</style>
