import { computed } from "vue";

import i18n from '@/plugins/i18n'

export const payStatusEnum = computed(() => [{
        enum: "created",
        description: i18n.t('payment_created'),
    },
    {
        enum: "refunded",
        description: i18n.t('payment_refunded'),
    },
    {
        enum: "canceled",
        description: i18n.t('payment_canceled'),
    },
    {
        enum: "need_approve",
        description: i18n.t('payment_need_approve'),
    },
    {
        enum: "hold",
        description: i18n.t('payment_hold'),
    },
    {
        enum: "clearing",
        description: i18n.t('payment_clearing'),
    },
    {
        enum: "withdraw",
        description: i18n.t('payment_withdraw'),
    },
    {
        enum: "refill",
        description: i18n.t('payment_refill'),
    },
    {
        enum: "processing",
        description: i18n.t('payment_processing'),
    },
    {
        enum: "process",
        description: i18n.t('payment_processing'),
    },
    {
        enum: "error",
        description: i18n.t('payment_error'),
    },
    {
        enum: "chargeback",
        description: i18n.t('payment_chargeback'),
    },
    {
        enum: "partial_refund",
        description: i18n.t('payment_partial_refund'),
    },
    {
        enum: "partial_clearing",
        description: i18n.t('payment_partial_clearing'),
    },
])

export const payTypeEnum = computed(() => [{
        enum: "pay",
        description: i18n.t('pay'),
    },
    {
        enum: "payout",
        description: i18n.t('payout'),
    },
    {
        enum: "transfer",
        description: i18n.t('transfer'),
    },
]);

export const payMethodEnum = computed(() => [{
        enum: "ecom",
        description: i18n.t('ecom'),
    },
    {
        enum: "mc",
        description: i18n.t('mc'),
    },
    {
        enum: "wallet",
        description: i18n.t('wallet'),
    },
    {
        enum: "ecom_to_ecom",
        description: i18n.t('ecom_to_ecom'),
    },
    {
        enum: "ecom_to_mc",
        description: i18n.t('ecom_to_mc'),
    },
    {
        enum: "mc_to_ecom",
        description: i18n.t('mc_to_ecom'),
    },
    {
        enum: "mc_to_mc",
        description: i18n.t('mc_to_mc'),
    },
    {
        enum: "google_pay",
        description: i18n.t('google_pay'),
    },
    {
        enum: "apple_pay",
        description: i18n.t('apple_pay'),
    }
]);

export const payErrorEnum = computed(() => [{
        enum: "provider_server_error",
        description: i18n.t('provider_server_error'),
    },
    {
        enum: "provider_common_error",
        description: i18n.t('provider_common_error'),
    },
    {
        enum: "provider_time_out",
        description: i18n.t('provider_time_out'),
    },
    {
        enum: "provider_incorrect_response_format",
        description: i18n.t('provider_incorrect_response_format'),
    },
    {
        enum: "ov_server_error",
        description: i18n.t('ov_server_error'),
    },
    {
        enum: "ov_routes_unavailable",
        description: i18n.t('ov_routes_unavailable'),
    },
    {
        enum: "ov_send_otp_error",
        description: i18n.t('ov_send_otp_error'),
    },
    {
        enum: "ov_incorrect_otp",
        description: i18n.t('ov_incorrect_otp'),
    },
    {
        enum: "ov_not_need_approve_status",
        description: i18n.t('ov_not_need_approve_status'),
    },
    {
        enum: "ov_payment_method_incorrect",
        description: i18n.t('ov_payment_method_incorrect'),
    },
    {
        enum: "ov_payment_type_incorrect",
        description: i18n.t('ov_payment_type_incorrect'),
    },

    {
        enum: "ov_create_operation_error",
        description: i18n.t('ov_create_operation_error'),
    },
    {
        enum: "ov_confirm_payment_error",
        description: i18n.t('ov_confirm_payment_error'),
    },
    {
        enum: "ov_cancel_payment_error",
        description: i18n.t('ov_cancel_payment_error'),
    },
    {
        enum: "ov_entity_not_found",
        description: i18n.t('ov_entity_not_found'),
    },
    {
        enum: "ov_entity_duplicate",
        description: i18n.t('ov_entity_duplicate'),
    },
    {
        enum: "ov_payment_not_found",
        description: i18n.t('ov_payment_not_found'),
    },
    {
        enum: "ov_payment_expired",
        description: i18n.t('ov_payment_expired'),
    },
    {
        enum: "ov_payment_already_processed",
        description: i18n.t('ov_payment_already_processed'),
    },
    {
        enum: "ov_operation_not_found",
        description: i18n.t('ov_operation_not_found'),
    },
    {
        enum: "ov_card_not_found",
        description: i18n.t('ov_card_not_found'),
    },
    {
        enum: "ov_card_incorrect_data",
        description: i18n.t('ov_card_incorrect_data'),
    },
    {
        enum: "ov_limit_payment_amount_min",
        description: i18n.t('ov_limit_payment_amount_min'),
    },
    {
        enum: "ov_limit_payment_amount",
        description: i18n.t('ov_limit_payment_amount'),
    },
    {
        enum: "ov_limit_payments_count",
        description: i18n.t('ov_limit_payments_count'),
    },
    {
        enum: "ov_limit_payments_daily_amount",
        description: i18n.t('ov_limit_payments_daily_amount'),
    },
    {
        enum: "ov_limit_payments_monthly_amount",
        description: i18n.t('ov_limit_payments_monthly_amount'),
    },
    {
        enum: "ov_limit_payments_daily_count",
        description: i18n.t('ov_limit_payments_daily_count'),
    },
    {
        enum: "ov_limit_payments_monthly_count",
        description: i18n.t('ov_limit_payments_monthly_count'),
    },
    {
        enum: "ov_commission_incorrect",
        description: i18n.t('ov_commission_incorrect'),
    },
    {
        enum: "ov_refund_amount_exceeded",
        description: i18n.t('ov_refund_amount_exceeded'),
    },
    {
        enum: "ov_refund_not_permitted",
        description: i18n.t('ov_refund_not_permitted'),
    },
    {
        enum: "ov_merchant_balance_insufficient",
        description: i18n.t('ov_merchant_balance_insufficient'),
    },
    {
        enum: "ov_merchant_balance_not_found",
        description: i18n.t('ov_merchant_balance_not_found'),
    },
    {
        enum: "ov_merchant_not_found",
        description: i18n.t('ov_merchant_not_found'),
    },
    {
        enum: "ov_not_two_stage_payment",
        description: i18n.t('ov_not_two_stage_payment'),
    },
    {
        enum: "ov_clear_amount_exceeded",
        description: i18n.t('ov_clear_amount_exceeded'),
    },
    {
        enum: "ov_anti_fraud_rejected",
        description: i18n.t('ov_anti_fraud_rejected'),
    },
    {
        enum: "ov_anti_fraud_internal_error",
        description: i18n.t('ov_anti_fraud_internal_error'),
    },
    {
        enum: "ov_anti_fraud_time_out",
        description: i18n.t('ov_anti_fraud_time_out'),
    },
    {
        enum: "ov_payment_lock",
        description: i18n.t('ov_payment_lock'),
    },
    {
        enum: "ov_balance_locked",
        description: i18n.t('ov_balance_locked'),
    },
    {
        enum: "provider_card_incorrect",
        description: i18n.t('provider_card_incorrect')
    },
    {
        enum: "provider_logic_error",
        description: i18n.t('provider_logic_error')
    },
    {
        enum: "provider_card_expired",
        description: i18n.t('provider_card_expired')
    },
    {
        enum: "provider_anti_fraud_error",
        description: i18n.t('provider_anti_fraud_error')
    },
    {
        enum: "provider_not_permitted_operation",
        description: i18n.t('provider_not_permitted_operation')
    },
    {
        enum: "provider_card_not_found",
        description: i18n.t('provider_card_not_found')
    },
    {
        enum: "provider_insufficient_balance",
        description: i18n.t('provider_insufficient_balance')
    },
    {
        enum: "provider_send_otp_error",
        description: i18n.t('provider_send_otp_error')
    },
    {
        enum: "provider_incorrect_otp_error",
        description: i18n.t('provider_incorrect_otp_error')
    },
    {
        enum: "provider_abonent_not_found",
        description: i18n.t('provider_abonent_not_found')
    },
    {
        enum: "provider_limit_error",
        description: i18n.t('provider_limit_error')
    },
    {
        enum: "provider_abonent_inactive",
        description: i18n.t('provider_abonent_inactive')
    },
    {
        enum: "provider_confirm_payment_error",
        description: i18n.t('provider_confirm_payment_error')
    },
    {
        enum: "provider_cancel_payment_error",
        description: i18n.t('provider_cancel_payment_error')
    },
    {
        enum: "provider_refund_payment_error",
        description: i18n.t('provider_refund_payment_error')
    },
    {
        enum: "provider_mpi_default_error",
        description: i18n.t('provider_mpi_default_error')
    },
    {
        enum: "provider_mpi_3ds_error",
        description: i18n.t('provider_mpi_3ds_error')
    },
    {
        enum: "provider_common_incorrect",
        description: i18n.t('provider_common_incorrect')
    },
    {
        enum: "provider_recurrent_error",
        description: i18n.t('provider_recurrent_error')
    },
    {
        enum: "mpi_default_error",
        description: i18n.t('mpi_default_error')
    },
    {
        enum: "mpi_3ds_error",
        description: i18n.t('mpi_3ds_error')
    },
    {
        enum: "ov_email_required",
        description: i18n.t('ov_email_required')
    },
]);