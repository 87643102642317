import { ref } from "vue";

export const balance_response_sample = ref({
    "balance": [{
        "currency": "string",
        "balance_amount": 0,
        "hold_amount": 0
    }]
})

export const response_samples = ref([{
        code: 200,
        data: {
            payment_id: "string",
            order_id: "string",
            payment_type: "string",
            payment_method: "string",
            payment_status: "create",
            error_code: "provider_server_error",
            recurrent_token: "string",
            amount: 0,
            amount_initial: 0,
            auth_amount: 0,
            captured_amount: 0,
            captured_details: {
                operation_id: 0,
                operation_created_at: "string",
                operation_amount: 0,
            },
            refunded_amount: 0,
            refund_details: [{
                operation_id: 0,
                operation_created_at: "string",
                operation_amount: 0,
            }, ],
            canceled_amount: 0,
            canceled_details: {
                operation_id: 0,
                operation_created_at: "string",
                operation_amount: 0,
            },
            created_date: "string",
            payer_info: {
                pan_masked: "string",
                holder: "string",
                email: "user@example.com",
                phone: "string",
                user_token: "string",
            },
            confirm_url: "string",
            payment_page_url: "string",
        },
    },
    {
        code: 400,
        data: {
            success: false,
            error_code: "provider_server_error",
            error_msg: "string",
        },
    },
    {
        code: 401,
        data: {
            success: false,
            error_code: "provider_server_error",
            error_msg: "string",
        },
    },
    {
        code: 403,
        data: {
            success: false,
            error_code: "provider_server_error",
            error_msg: "string",
        },
    },
]);