<template>
  <div class="api-section api-widget token-payout">
    <h1 class="api-h1">{{ $t('token_payments') }}</h1>
    <p class="api-widget__description">
    </p>
    <div class="token-code" v-highlight>
    <pre class="language-javascript">
      <code>
        Для v1:

        1й привязочный: (приём & выплата)

        params: {
          pay_token_flag: 1
        }

        2й: (платеж & выплата)

        card_data:{
          pay_token: "token"
        }

        Для v2:  

        1й привязочный:

        params: {  
          save_data: true
        }  
        
        2й:  

        payout_data:{  
          cardtoken: "token"
        }
      </code>
    </pre>
  </div>
  </div>
</template>

<script>
import { component as VueCodeHighlight } from "vue-code-highlight";
import "vue-code-highlight/themes/duotone-sea.css";
import "vue-code-highlight/themes/window.css";
export default {
  components: {
    VueCodeHighlight,
  },
};
</script>

<style lang="scss">
.api-widget {
  width: 60%;
  padding-bottom: 40px;
  .api-h1 {
    margin-bottom: 0;
  }
  &__description {
    margin-top: 0;
    padding: 0 40px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.4em;
    font-size: 15.5px;
    color: rgb(51, 51, 51);
  }
  .api-title {
    margin-top: 50px;
  }
  &__code {
    margin: 20px 40px;
    & > pre {
      padding-bottom: 0;
    }
  }
  &__screen {
    width: 100%;
    padding: 20px 40px 0;
    object-fit: contain;
    object-position: center center;
  }
  &__demo {
    padding: 40px 40px 0px;
    display: flex;
    align-items: center;
    span {
      margin-right: 15px;
      font-family: Montserrat, sans-serif;
      font-weight: 500;
      font-size: 22px;
      color: rgb(51, 51, 51);
    }
    button {
      border: 1.5px solid #19226d;
      background: transparent;
      color: #19226d;
      font-size: 14px;
      font-weight: 500;
      border-radius: 4px;
      height: 34px;
      padding: 0 20px;
      cursor: pointer;
      transition: 0.25s;
      &:hover {
        background: #19226d;
        color: white;
      }
    }
  }
}

.onevision-widget {
  z-index: 1111111111;
}

.token-code{
  padding: 0 40px;
}

@media (max-width: 939px) {
  .api-widget {
    width: 100%;
  }
}
</style>
