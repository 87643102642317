<template>
  <div>
    <div class="api-editor">
      <h3 class="color-white">{{ $t('request') }}</h3>
      <div class="custom-json-editor">
        <span class="brace">{</span>
        <div
          v-for="(value, key, index) in jsonData"
          :key="'key-' + index"
          class="json-row"
        >
          <span class="json-key">"{{ key }}"</span>
          <span class="colon">:</span>
          <span class="json-value">
            <span v-if="!isObject(value) && !isArray(value)">
              <input
                v-if="typeof jsonData[key] === 'number'"
                ref="inputs"
                v-model.number="jsonData[key]"
              />
              <input v-else ref="inputs" v-model="jsonData[key]" />
            </span>
            <span v-else>
              <button
                v-if="!isEmptyObject(value) || isArray(value)"
                @click="toggleCollapse(index)"
                class="collapse-btn"
              >
                {{ collapsedObjects[index] ? "+" : "-" }}
              </button>
              <json-editor
                v-if="!collapsedObjects[index] || isEmptyObject(value)"
                :data="value"
                :is-array="isArray(value)"
              />
            </span>
          </span>
        </div>
        <span class="brace">}</span>
      </div>
      <button class="api-editor__button" @click="sendData">{{ $t('send') }}</button>
    </div>
    <div class="api-editor" v-if="resultHidden[$props.id] == true">
      <h3 class="color-white">Ответ</h3>
      <div class="api-editor__status">
        <div class="status-tabs">
          <div
            class="status-tab"
            :class="{
              'color-error': resultStatus[$props.id] !== 200,
              'color-success': resultStatus[$props.id] === 200,
            }"
          >
            {{ resultStatus[$props.id] }}
          </div>
        </div>
      </div>
      <div class="custom-json-editor">
        <span class="brace">{</span>
        <div
          v-for="(value, key, index) in result[$props.id]"
          :key="'key-' + index"
          class="json-row"
        >
          <span class="json-key">"{{ key }}"</span>
          <span class="colon">:</span>
          <span class="json-value">
            <input ref="inputs" v-model="result[$props.id][key]" :disabled="true" />
          </span>
        </div>
        <span class="brace">}</span>
      </div>
    </div>
    <div class="api-editor" v-if="responseSamples">
      <h3 class="color-white">{{ $t('ex_res') }}</h3>
      <div class="api-editor__status">
        <div class="status-tabs">
          <div
            class="status-tab cursor-pointer"
            v-for="(item, i) in statusList"
            :key="i"
            @click="tabChange(i, $props.id)"
            :class="{
              'status-tab-active': i === activeTab[$props.id],
              'color-error': item.code != 200,
              'color-success': item.code == 200,
            }"
          >
            {{ item.code }}
          </div>
        </div>
      </div>
      <div class="custom-json-editor">
        <span class="brace">{</span>
        <div
          v-for="(value, key, index) in resSample[$props.id]"
          :key="'key-' + index"
          class="json-row"
        >
          <span class="json-key">"{{ key }}"</span>
          <span class="colon">:</span>
          <span class="json-value">
            <input
              v-if="!isObject(value) && !isArray(value)"
              ref="inputs"
              v-model="resSample[$props.id][key]"
              :disabled="true"
            />
            <span v-else>
              <button
                v-if="!isEmptyObject(value) || isArray(value)"
                @click="toggleCollapse(index)"
                class="collapse-btn"
              >
                {{ collapsedObjects[index] ? "+" : "-" }}
              </button>
              <json-editor
                v-if="!collapsedObjects[index] || isEmptyObject(value)"
                :data="value"
                :is-array="isArray(value)"
              />
            </span>
          </span>
        </div>
        <span class="brace">}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, set, watch, onMounted } from "vue";
import CryptoJS from "crypto-js";
import axios from "axios";

export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    json: {
      type: Object,
      default: () => ({}),
    },
    apiUrl: {
      type: String,
      default: "",
    },
    apiMethod: {
      type: String,
      default: "",
    },
    apiKey: {
      type: String,
      default: "",
    },
    secretKey: {
      type: String,
      default: "",
    },
    apiHostname: {
      type: String,
      default: "",
    },
    responseSamples: {
      type: Array,
      default: () => ({}),
    },
    apiResponseSamples: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      collapsedObjects: {},
    };
  },
  mounted() {
    for (const index in this.jsonData) {
      this.$set(this.collapsedObjects, index, true);
    }
  },
  methods: {
    isObject(value) {
      return value && typeof value === "object" && !Array.isArray(value);
    },
    isArray(value) {
      return Array.isArray(value);
    },
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0;
    },
    toggleCollapse(index) {
      this.$set(this.collapsedObjects, index, !this.collapsedObjects[index]);
    },
  },
  created() {
    const initializeCollapsed = (data) => {
      for (const key in data) {
        const value = data[key];
        if (this.isObject(value) || this.isArray(value)) {
          const id = this._uid + "-" + key;
          this.$set(this.collapsedObjects, id, true);
          initializeCollapsed(value);
        }
      }
    };
    initializeCollapsed(this.jsonData);
  },
  components: {
    JsonEditor: {
      functional: true,
      props: {
        data: {
          type: [Object, Array],
          required: true,
        },
        isArray: {
          type: Boolean,
          default: false,
        },
      },
      render(createElement, { props, parent }) {
        const renderElement = (key, value, index) => {
          const currentId = parent._uid + "-" + index;
          return createElement("div", { key: "key-" + index, class: "json-row" }, [
            !props.isArray && createElement("span", { class: "json-key" }, `"${key}"`),
            !props.isArray && createElement("span", { class: "colon" }, ":"),
            createElement("span", { class: "json-value" }, [
              !parent.isObject(value) && !parent.isArray(value)
                ? typeof value === "number"
                  ? createElement("input", {
                      attrs: { type: "number" },
                      domProps: { value },
                      on: {
                        input: (event) =>
                          (props.data[key] = parseFloat(event.target.value)),
                      },
                    })
                  : createElement("input", {
                      domProps: { value },
                      on: {
                        input: (event) => (props.data[key] = event.target.value),
                      },
                    })
                : [
                    (!parent.isEmptyObject(value) || parent.isArray(value)) &&
                      createElement(
                        "button",
                        {
                          on: {
                            click: () => parent.toggleCollapse(currentId),
                          },
                          class: "collapse-btn",
                        },
                        [parent.collapsedObjects[currentId] ? "+" : "-"]
                      ),
                    parent.collapsedObjects[currentId]
                      ? null
                      : createElement("json-editor", {
                          props: {
                            data: value,
                            isArray: parent.isArray(value),
                          },
                        }),
                  ],
            ]),
          ]);
        };

        const entries = props.isArray
          ? props.data.map((value, index) => [index, value])
          : Object.entries(props.data);

        return createElement("div", [
          createElement("span", { class: "brace" }, props.isArray ? "[" : "{"),
          ...entries.map(([key, value], index) => renderElement(key, value, index)),
          createElement("span", { class: "brace" }, props.isArray ? "]" : "}"),
        ]);
      },
    },
  },
  setup(props, { emit }) {
    const jsonData = reactive({ ...props.json });
    const resSample = ref({});
    const activeTab = ref({});
    const id = ref(props.id);
    const result = ref({});
    const resultStatus = ref({});
    const resultHidden = ref({});
    const statusList = ref([]);

    const base64ToJs = (base64String) => {
      const utf8Array = CryptoJS.enc.Base64.parse(base64String);
      const jsonString = CryptoJS.enc.Utf8.stringify(utf8Array);
      return JSON.parse(jsonString);
    };

    const convertStringToBase64 = (value) => {
      const utf8Array = CryptoJS.enc.Utf8.parse(value);
      const base64 = CryptoJS.enc.Base64.stringify(utf8Array);
      return base64;
    };

    const convertObjToBase64 = (data) => {
      const jsonString = JSON.stringify(data);
      const dataUtf8 = CryptoJS.enc.Utf8.parse(jsonString);
      const base64 = CryptoJS.enc.Base64.stringify(dataUtf8);
      return base64;
    };

    const createHmacSha512 = (data, key) => {
      const secretKeyUtf8 = CryptoJS.enc.Utf8.parse(key);
      const hmacHash = CryptoJS.HmacSHA512(data, secretKeyUtf8);
      const hmac = hmacHash.toString(CryptoJS.enc.Hex);
      return hmac;
    };

    const responseResult = (data, id, status) => {
      if (data.response_psp.data) {
        const newData = base64ToJs(data.response_psp.data);
        set(result.value, id, newData);
      } else {
        set(result.value, id, data.response_psp);
      }

      set(resultStatus.value, id, status);
      set(resultHidden.value, id, true);
      console.log(result.value, resultStatus.value, resultHidden.value);
    };

    const tabChange = (index, id) => {
      if (props.apiResponseSamples.length > 0) {
        const currentRes = props.apiResponseSamples.find((item, idx) => idx === index);
        set(resSample.value, id, currentRes.data);
        set(activeTab.value, id, index);
      } else {
        const currentRes = props.responseSamples.find((item, idx) => idx === index);
        set(resSample.value, id, currentRes.data);
        set(activeTab.value, id, index);
      }
    };

    const sendData = async () => {
      try {
        const axiosConfig = {
          method: "POST",
          url: "/",
          headers: {
            "Content-Type": "application/json",
          },
        };

        if (props.apiHostname.slice(-1) == "/") {
          props.apiHostname = props.apiHostname.slice(0, -1);
        }

        const payload = ref({
          endpoint: props.apiUrl,
          host: props.apiHostname,
          method: props.apiMethod,
          headers: {
            Authorization: "",
          },
          data: "",
          sign: "",
        });

        payload.value.headers.Authorization =
          "Bearer " + convertStringToBase64(props.apiKey);
        payload.value.data = convertObjToBase64(jsonData);
        payload.value.sign = createHmacSha512(payload.value.data, props.secretKey);

        axiosConfig.data = payload.value;

        const response = await axios(axiosConfig);

        if (response) {
          responseResult(response.data, props.id, response.data.status_code);
        }
      } catch (err) {
        console.log(err);
      }
    };

    onMounted(() => {
      if (props.responseSamples) {
        if (props.apiResponseSamples.length > 0) {
          set(resSample.value, props.id, props.apiResponseSamples[0].data);
          set(activeTab.value, props.id, 0);
          statusList.value = props.apiResponseSamples;
        } else {
          set(resSample.value, props.id, props.responseSamples[0].data);
          set(activeTab.value, props.id, 0);
          statusList.value = props.responseSamples;
        }
      }
    });

    return {
      id,
      jsonData,
      sendData,
      statusList,
      responseResult,
      result,
      resultStatus,
      resultHidden,
      resSample,
      tabChange,
      activeTab,
    };
  },
};
</script>

<style lang="scss">
.api-editor {
  h3 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .custom-json-editor {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: "Courier New", monospace;
    font-size: 14px;
    line-height: 1.5;
    color: #333;
    background-color: rgb(29 38 47);
    padding: 10px;
    border-radius: 4px;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  &__status {
    .status-tabs {
      display: flex;
      flex-wrap: wrap;
    }
    .status-tab {
      background-color: rgb(29 38 47);
      text-align: center;
      margin: 0 10px 10px 0;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 700;
      width: 60px;
      height: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      &-active {
        background: white;
      }
    }
  }
  .brace {
    color: #ffffff;
  }
  .json-row {
    display: flex;
    align-items: flex-start;
    margin-left: 10px;
    width: 100%;
    input {
      cursor: pointer;
    }
    & + .json-row {
      margin-top: 4px;
    }
    .collapse-btn {
      background: none;
      border: none;
      color: white;
      cursor: pointer;
    }
    .json-value {
      width: 100%;
      display: block;
      input {
        width: 100%;
        min-width: 60px;
      }
    }
  }
  .json-row-sub {
    margin-left: 10px;
  }
  .json-key {
    color: white;
    margin-right: 5px;
    font-weight: 400;
    font-family: "Courier New", monospace;
  }
  .colon {
    margin-right: 5px;
    color: #ffffff;
  }
  .json-value input {
    border: none;
    outline: none;
    font-family: "Courier New", monospace;
    font-size: 14px;
    color: #9df5a6;
    background-color: transparent;
    padding: 0;
  }
  .comma {
    margin-left: 0.25rem;
    color: #ffffff;
  }
  &__button {
    background-color: #006fe9;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    font-weight: 400;
    &:hover {
      background-color: #0159c7;
    }
  }
}
</style>
