<template>
  <div v-highlight>
    <pre class="language-javascript">
    <code>
    {{ script }}
    </code>
    </pre>
  </div>
</template>

<script>
import { ref } from "vue";
import { component as VueCodeHighlight } from "vue-code-highlight";
import "vue-code-highlight/themes/duotone-sea.css";
import "vue-code-highlight/themes/window.css";
export default {
  name: "WidgetCode",
  components: {
    VueCodeHighlight,
  },
  setup() {
    const script = ref(`
    sign = hash_hmac('sha512', $paymentId, $secret_key)
    `);

    return {
      script,
    };
  },
};
</script>
