<template>
  <div class="api-structure">
    <h1 class="api-h1">
      {{ $t('payment_execution_strc') }}
      <span class="grey-span">({{$t('payment_type')}}/{{$t('payment_method')}}/{{$t('payment_data')}})</span>
    </h1>
    <div class="structure">
      <h2 class="structure-title">Pay</h2>
      <div class="structure-boxs">
        <div class="structure-box">
          <div class="structure-box-title">ecom</div>
          <div class="structure-box-card">
            <span class="first">payment_data: {</span>
            <span class="value">pan:</span>
            <span class="value">holder:</span>
            <span class="value">year:</span>
            <span class="value">month:</span>
            <span class="value">cvc:</span>
            <span class="last">}</span>
          </div>
        </div>
        <div class="structure-box">
          <div class="structure-box-title">mc</div>
          <div class="structure-box-card">
            <span class="first">payment_data: {</span>
            <span class="value">Phone:</span>
            <span class="last">}</span>
          </div>
        </div>
        <div class="structure-box">
          <div class="structure-box-title">wallet</div>
          <div class="structure-box-card">
            <span class="first">payment_data: {</span>
            <span class="second">wallet_identifier: {</span>
            <span class="value">Id:</span>
            <span class="value">Phone:</span>
            <span class="third">}</span>
            <span class="last">}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="structure">
      <h2 class="structure-title">Payout</h2>
      <div class="structure-boxs">
        <div class="structure-box">
          <div class="structure-box-title">ecom</div>
          <div class="structure-box-card">
            <span class="first">payout_data: {</span>
            <span class="value">pan_to:</span>
            <span class="value">holder:</span>
            <span class="last">}</span>
          </div>
        </div>
        <div class="structure-box">
          <div class="structure-box-title">mc</div>
          <div class="structure-box-card">
            <span class="first">payout_data: {</span>
            <span class="value">phone_to:</span>
            <span class="last">}</span>
          </div>
        </div>
        <div class="structure-box">
          <div class="structure-box-title">wallet</div>
          <div class="structure-box-card">
            <span class="first">payout_data: {</span>
            <span class="second">wallet_identifier: {</span>
            <span class="value">id:</span>
            <span class="value">phone:</span>
            <span class="third">}</span>
            <span class="last">}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="structure">
      <h2 class="structure-title">Transfer</h2>
      <div class="structure-boxs">
        <div class="structure-box">
          <div class="structure-box-title">ecom_to_mc</div>
          <div class="structure-box-card">
            <span class="first">payment_data: {</span>
            <span class="value">pan:</span>
            <span class="value">holder:</span>
            <span class="value">year:</span>
            <span class="value">month:</span>
            <span class="value">cvc:</span>
            <span class="last">}</span>
            <span class="first">payout_data: {</span>
            <span class="value">phone_to:</span>
            <span class="last">}</span>
          </div>
        </div>
        <div class="structure-box">
          <div class="structure-box-title">ecom_to_ecom</div>
          <div class="structure-box-card">
            <span class="first">payment_data: {</span>
            <span class="value">pan:</span>
            <span class="value">holder:</span>
            <span class="value">year:</span>
            <span class="value">month:</span>
            <span class="value">cvc:</span>
            <span class="last">}</span>
            <span class="first">payout_data: {</span>
            <span class="value">pan_to:</span>
            <span class="value">holder:</span>
            <span class="last">}</span>
          </div>
        </div>
        <div class="structure-box">
          <div class="structure-box-title">mc_to_mc</div>
          <div class="structure-box-card">
            <span class="first">payment_data: {</span>
            <span class="value">phone:</span>
            <span class="last">}</span>
            <span class="first">payout_data: {</span>
            <span class="value">phone_to:</span>
            <span class="last">}</span>
          </div>
        </div>
        <div class="structure-box">
          <div class="structure-box-title">mc_to_ecom</div>
          <div class="structure-box-card">
            <span class="first">payment_data: {</span>
            <span class="value">phone:</span>
            <span class="last">}</span>
            <span class="first">payout_data: {</span>
            <span class="value">pan_to:</span>
            <span class="value">holder:</span>
            <span class="last">}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.structure {
  padding: 20px 20px;
  display: flex;
  border: 1px solid #7c7cba;
  margin: 40px 40px;
  &-title {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    position: relative;
    text-transform: uppercase;
    &::before {
      content: "";
      display: inline-block;
      width: 40px;
      height: 1px;
      background: rgb(124, 124, 187);
      position: absolute;
      right: -50px;
      top: 10px;
    }
    &::after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 1px;
      background: rgb(124, 124, 187);
      height: 10px;
      position: absolute;
      right: -10px;
      top: 5.5px;
    }
  }
  &-boxs {
    border-left: 1px solid #7c7cba;
    margin-left: 50px;
    margin-top: 10px;
    width: 100%;
  }
  &-box {
    margin-top: 15px;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    & + .structure-box {
      margin-top: 30px;
    }
    &-title {
      padding-left: 16px;
      font-size: 14px;
      text-transform: uppercase;
      padding-right: 5px;
      background: white;
      overflow: hidden;
      &::before {
        content: "";
        display: inline-block;
        width: 12px;
        height: 1px;
        background: rgb(124, 124, 187);
        position: absolute;
        left: 0;
        top: 8px;
      }
      &::after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 1px;
        background: rgb(124, 124, 187);
        height: 10px;
        position: absolute;
        left: 11px;
        top: 3.5px;
      }
    }
    &-card {
      border: 1px solid #7c7cba;
      padding: 12px 16px;
      position: relative;
      width: 50%;
      min-width: 150px;
      &::before {
        content: "";
        display: inline-block;
        width: 100%;
        height: 1px;
        background: rgb(124, 124, 187);
        position: absolute;
        left: -100%;
        top: 8px;
        z-index: -1;
      }
      span {
        display: block;
      }
      .value {
        padding-left: 5px;
      }
      .value,
      .second,
      .third {
        font-weight: 400;
        font-family: "Courier New", monospace;
      }
      .second {
        margin-bottom: 5px;
      }
      .third {
        margin-top: 5px;
      }
      .first {
        margin-bottom: 5px;
      }
      .last {
        margin-top: 5px;
      }
    }
  }
}
</style>
