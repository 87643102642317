<template>
  <div id="app">
    <div class="app__loader" v-if="isLoading">
      <loader />
    </div>
    <div class="app__inner" v-else>
      <sidebar :company_info="company_info" :api_list="apiList" />
      <section class="main">
        <div class="api-content">
          <div class="api-info">
            <h1>
              {{ company_info.name ? company_info.name : "" }}
              {{ $t("internet_acquiring") }}
              <span>(2.0.0)</span>
            </h1>
          </div>
          <div class="api-form">
            <div class="text-field">
              <label>Api KEY:</label>
              <input
                type="text"
                v-model="apiForm.api_key"
                :placeholder="$t('insert_api_key')"
              />
            </div>
            <div class="text-field">
              <label>Secret KEY:</label>
              <input
                type="text"
                v-model="apiForm.secret_key"
                :placeholder="$t('insert_secret_key')"
              />
            </div>
            <div class="text-field">
              <label for="">Api DOMAIN:</label>
              <input
                type="text"
                v-model="apiForm.hostname"
                :placeholder="$t('insert_api_url')"
              />
            </div>
          </div>
          <div class="api-block" id="block1">
            <div>
              <h2 class="api-title">{{ $t("description") }}</h2>
            </div>
            <p class="api-description">
              <b>{{ company_info.name ? company_info.name : "" }}</b> -
              {{ $t("onevision_desc") }}
            </p>
            <h3 class="api-h3">{{ $t("feedback") }}</h3>
            <div class="api-description">
              {{ $t("questions_can_sent") }}
              <b>{{ company_info.email ? company_info.email : "" }}</b
              >; {{ $t("contacts_website") }}
              <b>{{ company_info.landing_url ? company_info.landing_url : "" }}</b>
            </div>
            <h3 class="api-h3">{{ $t("stages_implementation") }}</h3>
            <ul class="api-list">
              <li>{{ $t("conc_agreement") }}</li>
              <li>{{ $t("partner_reg") }}</li>
              <li>{{ $t("development_api") }}</li>
              <li>
                {{ $t("testing_func") }}
              </li>
              <li>{{ $t("launch_tested_sol") }}</li>
              <li>
                {{ $t("post_check") }}
              </li>
            </ul>
            <h3 class="api-h3">{{ $t("req_format") }}</h3>
            <p class="api-description">
              <i18n path="api_support">
                <strong place="post">POST</strong>
                <strong place="content_type">Content-type</strong>
                <strong place="app_json">application/json</strong>
              </i18n>
            </p>
            <table class="api-table">
              <thead>
                <tr>
                  <th>{{ $t("parameter") }}</th>
                  <th>{{ $t("description") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ $t("header") }}</td>
                  <td>key = "Authorization" value = Bearer { token }</td>
                </tr>
                <tr>
                  <td>data</td>
                  <td>{{ $t("mime_json") }}</td>
                </tr>
                <tr>
                  <td>sign</td>
                  <td>{{ $t("req_sign") }}</td>
                </tr>
                <tr>
                  <td>{{ $t("req_format2") }}</td>
                  <td>application/json</td>
                </tr>
                <tr>
                  <td>{{ $t("res_format") }}</td>
                  <td>application/json</td>
                </tr>
                <tr>
                  <td>{{ $t("encoding") }}</td>
                  <td>UTF-8</td>
                </tr>
              </tbody>
            </table>
            <h3 class="api-h3">{token} = base64_encode(api_key)</h3>
            <h3 class="api-h3">{{ $t("sign_formation") }}</h3>
            <p class="api-description">
              <i18n path="sign_formation_desc">
                <strong place="data">data</strong>
                <strong place="hash_hmac">hash_hmac</strong>
                <strong place="sha">"sha512"</strong>
              </i18n>
            </p>
            <p class="api-description">
              <strong>hash_hmac('sha512', $data, $secret_key)</strong> {{ $t("where") }}:
            </p>
            <p class="api-description"><strong>$data</strong> - {{ $t("mime_json") }}</p>
            <p class="api-description">
              <strong>$secret_key</strong> - {{ $t("store_password_manager") }}
            </p>
            <div class="api-code">
              <p class="api-description">{{ $t("ex_implementation_php") }}:</p>
              <div class="api-code__card">
                <realizationCode />
              </div>
              <p class="api-description">{{ $t("ex_res_php") }}:</p>
              <div class="api-code__card">
                <responseCode />
              </div>
            </div>
            <div id="enums">
              <enum-descriptions :title="$t('payment_status')" :list="payStatusEnum" />
              <enum-descriptions :title="$t('payment_types')" :list="payTypeEnum" />
              <enum-descriptions :title="$t('payment_methods')" :list="payMethodEnum" />
              <enum-descriptions :title="$t('errors')" :list="payErrorEnum" />
            </div>
            <structure id="structure" />
          </div>
          <div class="api-sections">
            <div class="api-section" id="block2">
              <h1 class="api-h1">
                {{ $t("payment_crt") }}
                <span class="grey-span">{{ $t("via_payment_page") }}</span>
              </h1>
              <div
                class="api-wrap"
                :id="api.id ? api.id : ''"
                v-for="(api, i) in apiList.filter((val, idx) => idx === 0)"
                :key="i"
              >
                <div class="api-block">
                  <div>
                    <h2 class="api-title" v-if="api.title">{{ api.title }}</h2>
                    <p class="api-description" v-if="api.description">
                      {{ api.description }}
                    </p>
                  </div>
                  <div class="api-schema">
                    <div class="schema">
                      <h5 class="schema__header">
                        {{ $t("req_body_schema") }}:
                        <span>application/json</span>
                      </h5>
                      <table class="schema__table table-thead-hidden">
                        <thead>
                          <th>name</th>
                          <th>{{ $t("description") }}</th>
                        </thead>
                        <tbody>
                          <tr v-for="(list, i) in api.schema" :key="i">
                            <td class="td-first">
                              <span class="arrow"></span>
                              <span class="property-name" v-if="list.key">{{
                                list.key
                              }}</span>
                              <span class="required" v-if="list.required">
                                required
                              </span>
                            </td>
                            <td class="td-second">
                              <div>
                                <div>
                                  <span class="type" v-if="list.type">
                                    {{ list.type }}
                                  </span>
                                  <span v-if="list.characters" class="characters">
                                    {{ list.characters }}
                                  </span>
                                </div>
                                <div v-if="list.type_value_list && list.type_value_title">
                                  <span class="type-title" v-if="list.type_value_title">
                                    {{ list.type_value_title }}:
                                  </span>
                                  <span
                                    v-for="(listValue, i) in list.type_value_list"
                                    :key="i"
                                    class="type-list"
                                    :class="{
                                      'cursor-pointer':
                                        list.type_value_title == 'Enum' ? true : false,
                                    }"
                                    @click="scrollToDescription(list.type_value_title)"
                                  >
                                    {{ listValue }}
                                  </span>
                                </div>
                                <div>
                                  <p class="description" v-if="list.description">
                                    {{ list.description }}
                                  </p>
                                </div>
                                <div v-if="list.items">
                                  <table
                                    class="schema__table table-thead-hidden schema__table-children"
                                  >
                                    <caption>
                                      Schema table
                                    </caption>
                                    <thead>
                                      <th>name</th>
                                      <th>description</th>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(subList, v) in list.items" :key="v">
                                        <td class="td-first">
                                          <span class="arrow"></span>
                                          <span
                                            class="property-name"
                                            v-if="subList.key"
                                            >{{ subList.key }}</span
                                          >
                                          <span class="required" v-if="subList.required">
                                            required
                                          </span>
                                        </td>
                                        <td class="td-second">
                                          <div>
                                            <div>
                                              <span class="type" v-if="subList.type">
                                                {{ subList.type }}
                                              </span>
                                              <span
                                                v-if="subList.characters"
                                                class="characters"
                                              >
                                                {{ subList.characters }}
                                              </span>
                                            </div>
                                            <div>
                                              <p
                                                class="description"
                                                v-if="subList.description"
                                              >
                                                {{ subList.description }}
                                              </p>
                                            </div>
                                            <div v-if="subList.child_items">
                                              <table
                                                class="schema__table table-thead-hidden schema__table-children"
                                              >
                                                <caption>
                                                  Schema table
                                                </caption>
                                                <thead>
                                                  <th>name</th>
                                                  <th>description</th>
                                                </thead>
                                                <tbody>
                                                  <tr
                                                    v-for="(
                                                      childList, v2
                                                    ) in subList.child_items"
                                                    :key="v2"
                                                  >
                                                    <td class="td-first">
                                                      <span class="arrow"></span>
                                                      <span
                                                        class="property-name"
                                                        v-if="childList.key"
                                                        >{{ childList.key }}</span
                                                      >
                                                      <span
                                                        class="required"
                                                        v-if="childList.required"
                                                      >
                                                        required
                                                      </span>
                                                    </td>
                                                    <td class="td-second">
                                                      <div>
                                                        <div>
                                                          <span
                                                            class="type"
                                                            v-if="childList.type"
                                                          >
                                                            {{ childList.type }}
                                                          </span>
                                                          <span
                                                            v-if="childList.characters"
                                                            class="characters"
                                                          >
                                                            {{ childList.characters }}
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <p
                                                            class="description"
                                                            v-if="childList.description"
                                                          >
                                                            {{ childList.description }}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="response"></div>
                </div>
                <div class="api-request">
                  <request-route
                    :requestMethod="api.method"
                    :requestRoute="api.endpoint"
                  />
                  <editor
                    :id="api.id"
                    :json="api.payload"
                    :api-url="api.endpoint"
                    :api-method="api.method"
                    :api-key="apiForm.api_key"
                    :secret-key="apiForm.secret_key"
                    :api-hostname="apiForm.hostname"
                    :response-samples="response_samples"
                    :api-response-samples="api.response"
                  />
                </div>
              </div>
            </div>
            <div class="api-section" id="block3">
              <h1 class="api-h1">
                {{ $t("host_host") }}
              </h1>
              <div
                class="api-wrap"
                :id="api.id ? api.id : ''"
                v-for="(api, i) in apiList.filter((val, idx) => idx === 1)"
                :key="i"
              >
                <div class="api-block">
                  <div>
                    <h2 class="api-title" v-if="api.title">{{ api.title }}</h2>
                    <p class="api-description" v-if="api.description">
                      {{ api.description }}
                    </p>
                  </div>
                  <div class="api-schema">
                    <div class="schema">
                      <h5 class="schema__header">
                        {{ $t("req_body_schema") }}:
                        <span>application/json</span>
                      </h5>
                      <table class="schema__table table-thead-hidden">
                        <caption>
                          Schema table
                        </caption>
                        <thead>
                          <th>name</th>
                          <th>description</th>
                        </thead>
                        <tbody>
                          <tr v-for="(list, i) in api.schema" :key="i">
                            <td class="td-first">
                              <span class="arrow"></span>
                              <span class="property-name" v-if="list.key">{{
                                list.key
                              }}</span>
                              <span class="required" v-if="list.required">
                                required
                              </span>
                            </td>
                            <td class="td-second">
                              <div>
                                <div>
                                  <span class="type" v-if="list.type">
                                    {{ list.type }}
                                  </span>
                                  <span v-if="list.characters" class="characters">
                                    {{ list.characters }}
                                  </span>
                                </div>
                                <div v-if="list.type_value_list && list.type_value_title">
                                  <span class="type-title" v-if="list.type_value_title"
                                    >{{ list.type_value_title }}:</span
                                  >
                                  <span
                                    v-for="(listValue, i) in list.type_value_list"
                                    :key="i"
                                    class="type-list"
                                    :class="{
                                      'cursor-pointer':
                                        list.type_value_title == 'Enum' ? true : false,
                                    }"
                                    @click="scrollToDescription(list.type_value_title)"
                                  >
                                    {{ listValue }}
                                  </span>
                                </div>
                                <div>
                                  <p class="description" v-if="list.description">
                                    {{ list.description }}
                                  </p>
                                </div>
                                <div v-if="list.items">
                                  <table
                                    class="schema__table table-thead-hidden schema__table-children"
                                  >
                                    <caption>
                                      Schema table
                                    </caption>
                                    <thead>
                                      <th>name</th>
                                      <th>description</th>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(subList, v) in list.items" :key="v">
                                        <td class="td-first">
                                          <span class="arrow"></span>
                                          <span
                                            class="property-name"
                                            v-if="subList.key"
                                            >{{ subList.key }}</span
                                          >
                                          <span class="required" v-if="subList.required">
                                            required
                                          </span>
                                        </td>
                                        <td class="td-second">
                                          <div>
                                            <div>
                                              <span class="type" v-if="subList.type">
                                                {{ subList.type }}
                                              </span>
                                              <span
                                                v-if="subList.characters"
                                                class="characters"
                                              >
                                                {{ subList.characters }}
                                              </span>
                                            </div>
                                            <div>
                                              <p
                                                class="description"
                                                v-if="subList.description"
                                              >
                                                {{ subList.description }}
                                              </p>
                                            </div>
                                            <div v-if="subList.child_items">
                                              <table
                                                class="schema__table table-thead-hidden schema__table-children"
                                              >
                                                <caption>
                                                  Schema table
                                                </caption>
                                                <thead>
                                                  <th>name</th>
                                                  <th>description</th>
                                                </thead>
                                                <tbody>
                                                  <tr
                                                    v-for="(
                                                      childList, v2
                                                    ) in subList.child_items"
                                                    :key="v2"
                                                  >
                                                    <td class="td-first">
                                                      <span class="arrow"></span>
                                                      <span
                                                        class="property-name"
                                                        v-if="childList.key"
                                                        >{{ childList.key }}</span
                                                      >
                                                      <span
                                                        class="required"
                                                        v-if="childList.required"
                                                      >
                                                        required
                                                      </span>
                                                    </td>
                                                    <td class="td-second">
                                                      <div>
                                                        <div>
                                                          <span
                                                            class="type"
                                                            v-if="childList.type"
                                                          >
                                                            {{ childList.type }}
                                                          </span>
                                                          <span
                                                            v-if="childList.characters"
                                                            class="characters"
                                                          >
                                                            {{ childList.characters }}
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <p
                                                            class="description"
                                                            v-if="childList.description"
                                                          >
                                                            {{ childList.description }}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="response"></div>
                </div>
                <div class="api-request">
                  <request-route
                    :requestMethod="api.method"
                    :requestRoute="api.endpoint"
                  />
                  <editor
                    :id="api.id"
                    :json="api.payload"
                    :api-url="api.endpoint"
                    :api-method="api.method"
                    :api-key="apiForm.api_key"
                    :secret-key="apiForm.secret_key"
                    :api-hostname="apiForm.hostname"
                    :response-samples="response_samples"
                    :api-response-samples="api.response"
                  />
                </div>
              </div>
            </div>
            <div class="api-section" id="block4">
              <h1 class="api-h1">{{ $t("gen_inquiries") }}</h1>
              <div
                class="api-wrap"
                :id="api.id ? api.id : ''"
                v-for="(api, i) in apiList.slice(2)"
                :key="i"
              >
                <div class="api-block">
                  <div>
                    <h2 class="api-title" v-if="api.title">{{ api.title }}</h2>
                    <p class="api-description" v-if="api.description">
                      {{ api.description }}
                    </p>
                  </div>
                  <div class="api-schema">
                    <div class="schema">
                      <h5 class="schema__header">
                        {{ $t("req_body_schema") }}:
                        <span>application/json</span>
                      </h5>
                      <table class="schema__table table-thead-hidden">
                        <caption>
                          Schema table
                        </caption>
                        <thead>
                          <th>name</th>
                          <th>description</th>
                        </thead>
                        <tbody>
                          <tr v-for="(list, i) in api.schema" :key="i">
                            <td class="td-first">
                              <span class="arrow"></span>
                              <span class="property-name" v-if="list.key">{{
                                list.key
                              }}</span>
                              <span class="required" v-if="list.required">
                                required
                              </span>
                            </td>
                            <td class="td-second">
                              <div>
                                <div>
                                  <span class="type" v-if="list.type">
                                    {{ list.type }}
                                  </span>
                                  <span v-if="list.characters" class="characters">
                                    {{ list.characters }}
                                  </span>
                                </div>
                                <div v-if="list.type_value_list && list.type_value_title">
                                  <span class="type-title" v-if="list.type_value_title"
                                    >{{ list.type_value_title }}:</span
                                  >
                                  <span
                                    v-for="(listValue, i) in list.type_value_list"
                                    :key="i"
                                    class="type-list"
                                    :class="{
                                      'cursor-pointer':
                                        list.type_value_title == 'Enum' ? true : false,
                                    }"
                                    @click="scrollToDescription(list.type_value_title)"
                                  >
                                    {{ listValue }}
                                  </span>
                                </div>
                                <div>
                                  <p class="description" v-if="list.description">
                                    {{ list.description }}
                                  </p>
                                </div>
                                <div v-if="list.items">
                                  <table
                                    class="schema__table table-thead-hidden schema__table-children"
                                  >
                                    <caption>
                                      Schema table
                                    </caption>
                                    <thead>
                                      <th>name</th>
                                      <th>description</th>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(subList, v) in list.items" :key="v">
                                        <td class="td-first">
                                          <span class="arrow"></span>
                                          <span
                                            class="property-name"
                                            v-if="subList.key"
                                            >{{ subList.key }}</span
                                          >
                                          <span class="required" v-if="subList.required">
                                            required
                                          </span>
                                        </td>
                                        <td class="td-second">
                                          <div>
                                            <div>
                                              <span class="type" v-if="subList.type">
                                                {{ subList.type }}
                                              </span>
                                              <span
                                                v-if="subList.characters"
                                                class="characters"
                                              >
                                                {{ subList.characters }}
                                              </span>
                                            </div>
                                            <div>
                                              <p
                                                class="description"
                                                v-if="subList.description"
                                              >
                                                {{ subList.description }}
                                              </p>
                                            </div>
                                            <div v-if="subList.child_items">
                                              <table
                                                class="schema__table table-thead-hidden schema__table-children"
                                              >
                                                <caption>
                                                  Schema table
                                                </caption>
                                                <thead>
                                                  <th>name</th>
                                                  <th>description</th>
                                                </thead>
                                                <tbody>
                                                  <tr
                                                    v-for="(
                                                      childList, v2
                                                    ) in subList.child_items"
                                                    :key="v2"
                                                  >
                                                    <td class="td-first">
                                                      <span class="arrow"></span>
                                                      <span
                                                        class="property-name"
                                                        v-if="childList.key"
                                                        >{{ childList.key }}</span
                                                      >
                                                      <span
                                                        class="required"
                                                        v-if="childList.required"
                                                      >
                                                        required
                                                      </span>
                                                    </td>
                                                    <td class="td-second">
                                                      <div>
                                                        <div>
                                                          <span
                                                            class="type"
                                                            v-if="childList.type"
                                                          >
                                                            {{ childList.type }}
                                                          </span>
                                                          <span
                                                            v-if="childList.characters"
                                                            class="characters"
                                                          >
                                                            {{ childList.characters }}
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <p
                                                            class="description"
                                                            v-if="childList.description"
                                                          >
                                                            {{ childList.description }}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="response"></div>
                </div>
                <div class="api-request">
                  <request-route
                    :requestMethod="api.method"
                    :requestRoute="api.endpoint"
                  />
                  <editor
                    :id="api.id"
                    :json="api.payload"
                    :api-url="api.endpoint"
                    :api-method="api.method"
                    :api-key="apiForm.api_key"
                    :secret-key="apiForm.secret_key"
                    :api-hostname="apiForm.hostname"
                    :response-samples="response_samples"
                    :api-response-samples="api.response"
                  />
                </div>
              </div>
            </div>
            <dsFlow id="block5" />
            <dsFlowMc id="block6" />
            <token-payout id="tokenPayout"> </token-payout>
            <save-card id="saveCard" />
            <widget
              v-if="true"
              id="payment-widget"
              :widgetUrl="company_info.widget_url"
              :krakendUrl="company_info.gateway_url"
            />
            <google-pay id="google-pay" />
            <test-mode id="test-mode" />
            <msdk id="msdk" />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, set, computed } from "vue";
import loader from "./components/loader.vue";
import requestRoute from "./components/route.vue";
import editor from "./components/editor.vue";
import realizationCode from "./components/realizationCode.vue";
import responseCode from "./components/responseCode.vue";
import enumDescriptions from "./components/enumDescriptions.vue";
import { useFonts } from "./helpers/useFonts";
import { response_samples } from "./helpers/response_variables";
import {
  payTypeEnum,
  payMethodEnum,
  payErrorEnum,
  payStatusEnum,
} from "./helpers/enum_variables";
import structure from "./components/structure.vue";
import sidebar from "./components/sidebar.vue";
import Widget from "./components/widget.vue";
import dsFlow from "./components/dsFlow.vue";
import dsFlowMc from "./components/dsFlowMc.vue";
import CryptoJS from "crypto-js";
import testMode from "./components/testMode.vue";
import GooglePay from "./components/googlepay.vue";
import TokenPayout from "./components/tokenPayout.vue";
import Msdk from "./components/msdk.vue";
import SaveCard from "./components/saveCard.vue";
import axios from "axios";
import i18n from "@/plugins/i18n";
import apiListEn from '@/jsons/apiList_en.json';
import apiListRu from '@/jsons/apiList_ru.json';

export default {
  name: "Documentation",
  components: {
    realizationCode,
    responseCode,
    requestRoute,
    enumDescriptions,
    editor,
    loader,
    structure,
    sidebar,
    Widget,
    dsFlow,
    dsFlowMc,
    testMode,
    GooglePay,
    TokenPayout,
    SaveCard,
    Msdk
  },
  setup() {
    useFonts();
    const isLoading = ref(true);
    const company_info = ref();

    const apiList = computed(() => {
      return i18n.locale === 'en' ? apiListEn : apiListRu;
    });

    const apiForm = reactive({
      api_key: "",
      secret_key: "",
      hostname: "",
    });

    const base64ToJs = (base64String) => {
      const utf8Array = CryptoJS.enc.Base64.parse(base64String);
      const jsonString = CryptoJS.enc.Utf8.stringify(utf8Array);
      return JSON.parse(jsonString);
    };

    const scrollToDescription = (Enum) => {
      if (Enum === "Enum") {
        console.log(Enum);
        const target = "#enums";
        const element = document.querySelector(target);

        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    };

    const getCompanyInfo = async () => {
      try {
        const response = await axios.get("/company-info/" + window.location.hostname);

        // const response = await axios.get(
        //   "https://mocki.io/v1/0643a04e-e741-41c4-b9be-20aec109b673"
        // );

        if (response) {
          company_info.value = response.data;

          // company_info.value.apiList = i18n.locale === 'en' ? apiListEn : apiListRu;
   
          // company_info.value.apiList = company_info.value.apiList.map((item, i) => {
          //   return base64ToJs(item);
          // });

          isLoading.value = false;
        }
      } catch (err) {
        console.log(err);
      }
    };

    onMounted(() => {
      getCompanyInfo();
    });

    return {
      apiList,
      apiForm,
      company_info,
      response_samples,
      payStatusEnum,
      payTypeEnum,
      payMethodEnum,
      payErrorEnum,
      isLoading,
      base64ToJs,
      scrollToDescription,
    };
  },
};
</script>

<style lang="scss">
.app__inner {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  .sidebar {
    width: 260px;
    background-color: rgb(250, 250, 250);
    overflow: auto;
    display: flex;
    flex-direction: column;
    backface-visibility: hidden;
    height: 100vh;
    position: sticky;
    top: 0px;
  }
  .main {
    z-index: 1;
    position: relative;
    overflow: hidden;
    width: calc(100% - 260px);
    contain: layout;
    display: flex;
    justify-content: space-between;
    height: 100vh;
    overflow: auto;
    background: linear-gradient(to right, white 60%, rgb(38, 50, 56) 40%);
         
  }
}

 
</style>
