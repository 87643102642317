<template>
  <div class="api-section ds-flow">
    <h1 class="api-h1">{{ $t('payment_conf') }}</h1>
    <div class="api-wrap">
      <div class="api-block">
        <div class="ds-flow__wrap">
          <p class="ds-flow__p">{{ $t('procc_payment_conf_3ds') }}:</p>
          <p class="ds-flow__p">
            <i18n path="after_sending_payment">
              <b place="pay">"pay"</b>
              <b place="ecom">"ecom"</b>
            </i18n>
            </p>
          <div class="ds-flow__title">NON 3DS</div>
          <p class="ds-flow__p">
            {{ $t('no_3ds_valid') }}
          </p>
          <div class="ds-flow__title">Fingerprint</div>
          <p class="ds-flow__p">
            <i18n path="fingerprint">
              <b place="fingerprint">(fingerprint)</b>
              <b place="confirm_url">"confirm_url"</b>
              <b place="confirm_url_link">"https://www.payment.confirm_url"</b>
            </i18n>
          </p>
          <div class="ds-flow__title">Frictionless</div>
          <p class="ds-flow__p">
            <i18n path="frictionless">
              <b place="frictionless">(frictionless)</b>
            </i18n>
          </p>
          <p class="ds-flow__p"><b>"status"</b> = <b>"success"</b> / <b>"error"</b></p>
          <p class="ds-flow__p">
            <!-- В случае ошибки <b>"status"</b> = <b>"error"</b>, вы также получите
            <b>"error_code"</b> =
            <b>"код_ошибки"</b> -->
            <i18n path="in_case_error">
              <b place="status">"status"</b>
              <b place="error">"error"</b>
              <b place="error_code">"error_code"</b>
              <b place="code_error">"{{ $t('code_error') }}"</b>
            </i18n>
          </p>
          <p class="ds-flow__p">
            <i18n path="verify_auth_data_payment">
              <b place="sign">"sign"</b>
              <b place="hmac">HMAC-SHA512</b>
              <b place="secret_key">$paymentId, $secret_key.</b>
            </i18n>
          </p>
          <dsFlowCode2 class="ds-flow__code ds-flow__code2" />
          <p class="ds-flow__p">
            <i18n path="ex_data_merchant">
              <b place="post">POST</b>
            </i18n>
          </p>
          <dsFlowCode1 class="ds-flow__code" />
          <p class="ds-flow__p">
            {{ $t('after_receiving_status') }}
          </p>
          <div class="ds-flow__title">3DS Challenge</div>
          <p class="ds-flow__p">
            <i18n path="3ds_challenge">
              <b place="confirm_url">"confirm_url"</b>
              <b place="confirm_url_link">"https://www.payment.confirm_url"</b>
              <b place="merchant_term_url">"merchant_term_url"</b>
            </i18n>
          </p>
          <p class="ds-flow">
            <b>TermUrl</b> = <b>merchant_term_url</b> {{ $t('field_req_payment') }}
          </p>
          <p class="ds-flow__p"><b>"status"</b> = <b>"need_approve"</b></p>
          <p class="ds-flow__p">
            <i18n path="verify_auth_data_payment">
              <b place="sign">"sign"</b>
              <b place="hmac">HMAC-SHA512</b>
              <b place="secret_key">$paymentId, $secret_key.</b>
            </i18n>
          </p>
          <dsFlowCode2 class="ds-flow__code ds-flow__code2" />
          <p class="ds-flow__p">
            <i18n path="ex_data_merchant">
              <b place="post">POST</b>
            </i18n>
          </p>
          <dsFlowCode1 class="ds-flow__code" />
          <p class="ds-flow__p">
            <i18n path="payment_final">
              <b place="status">"status"</b>
              <b place="need_approve">"need_approve"</b>
            </i18n>
          </p>
          <p class="ds-flow__p">
            <b>URL: https://host/payment/confirm </b> {{ $t('gen_req_payment_conf') }}
          </p>
          <p class="ds-flow__p">
            {{$t('payment_final_req')}}
          </p>
          <p class="ds-flow__p">
            <b> payment_id, confirm_id, confirm_res, confirm_otp, confirm_code</b>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dsFlowCode1 from "./dsFlowCode1.vue";
import dsFlowCode2 from "./dsFlowCode2.vue";
export default {
  components: {
    dsFlowCode1,
    dsFlowCode2,
  },
};
</script>

<style lang="scss">
.ds-flow {
  .api-h1 {
    padding-bottom: 0;
  }
  &__wrap {
    overflow: hidden;
    margin-left: 40px;
    padding: 0 40px 0 30px;
    border-left: 1px solid rgb(124, 124, 187);
  }
  &__title {
    margin-bottom: 20px;
    margin-top: 40px;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 60px;
      height: 1px;
      left: -70px;
      bottom: 11px;
      background: #7c7cba;
    }
    &::before {
      content: "";
      position: absolute;
      background: #7c7cba;
      left: -10px;
      bottom: 3px;
      width: 1.5px;
      height: 17px;
    }
  }
  &__p {
    margin-top: 0;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.4em;
    font-size: 15.5px;
    color: rgb(51, 51, 51);
  }
  &__code {
    margin: 30px 0;
    & > pre {
      padding-bottom: 0;
    }
  }
  &__code2 {
    code[class*="language-"],
    pre[class*="language-"] {
      line-height: 0.6;
    }
  }
}
</style>
