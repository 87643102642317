import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "normalize.css"
import './styles/index.scss'
import VueCodeHighlight from 'vue-code-highlight';
import i18n from '@/plugins/i18n';

Vue.use(VueCodeHighlight)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
    i18n
}).$mount('#app')