import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/lang/EN.json';
import ru from '@/lang/RU.json';

Vue.use(VueI18n);

export default new VueI18n({
    legacy: false,
    locale: 'ru',
    fallbackLocale: "ru",
    messages: {
        en: en,
        ru: ru
    },
    globalInjection: true,
})