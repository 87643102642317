<template>
  <div v-highlight>
    <pre class="language-javascript">
      <code v-html="codeWithTranslations"></code>
    </pre>
  </div>
</template>

<script>
import VueCodeHighlight from "vue-code-highlight";
import "vue-code-highlight/themes/duotone-sea.css";
import "vue-code-highlight/themes/window.css";
import i18n from "@/plugins/i18n";

export default {
  components: {
    VueCodeHighlight,
  },
  computed: {
    codeWithTranslations() {
      const currentLocale = this.$i18n.locale;
      
      const translations = {
        en: {
          successComment: 'in case of successful operation you get true, in case of error false',
          paymentIdComment: 'payment id (in case of successful operation)',
          dataComment: 'payment object in json format encoded in base64_encode (in case of successful operation)',
          signComment: 'response signature (in case of successful operation)',
          errorCodeComment: 'error code in case of unsuccessful operation',
          errorMsgComment: 'error message in case of unsuccessful operation'
        },
        ru: {
          successComment: 'в случае успешной операции получаете true, в случае ошибки false',
          paymentIdComment: 'id платежа (в случае успешной операции)',
          dataComment: 'объект платежа в формате json закодированный в base64_encode (в случае успешной операции)',
          signComment: 'подпись ответа (в случае успешной операции)',
          errorCodeComment: 'код ошибки при не успешной операции',
          errorMsgComment: 'сообщение ошибки при не успешной операции'
        }
      };

      const t = translations[currentLocale];

      return `
        &lt;?php
          $obj = [
            "success" => boolean, // ${t.successComment}
            "payment_id" => string, // ${t.paymentIdComment}
            "data" => base64_encode(json_encode($payment_object)), // ${t.dataComment}
            "sign" => hash_hmac('sha512', $data, $secret_key), // ${t.signComment}
            "error_code" => string, // ${t.errorCodeComment}
            "error_msg" => string // ${t.errorMsgComment}
          ];
        ?&gt;
      `;
    }
  }
};
</script>
