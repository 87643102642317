<template>
  <div class="api-block google-pay">
    <div>
      <h2 class="api-h1" style="max-width: 100%">{{ $t('pay_google') }}</h2>
    </div>
    <p class="api-description">
      <i18n path="pay_google_desc">
        <b place="google_pay">Google Pay ™</b>
      </i18n>
    </p>
    <h2 class="api-h3">{{ $t('benefits_google_pay') }}</h2>
    <p class="api-description">
      <i18n path="conv_google_pay">
        <b place="conv">{{ $t('conv') }}</b>
      </i18n>
    </p>
    <p class="api-description">
      <i18n path="speed_google_pay">
        <b place="speed">{{ $t('speed') }}</b>
      </i18n>
    </p>
    <p class="api-description">
      <i18n path="security_google_pay">
        <b place="security">{{ $t('security') }}</b>
      </i18n>
    </p>
    <p class="api-description">
      <i18n path="support_google_pay">
        <b place="support">{{ $t('support') }}</b>
      </i18n>
    </p>
    <h2 class="api-title" style="margin-top: 40px">{{ $t('google_pay_works') }}</h2>
    <h3 class="api-h3">{{ $t('nfc_tech') }}</h3>
    <p class="api-description">
      {{ $t('nfc_google_pay') }}
    </p>
    <h3 class="api-h3">{{ $t('online_shopping') }}</h3>
    <p class="api-description">
      {{ $t('shopping_google_pay') }}
    </p>
    <h3 class="api-h3">{{ $t('compability') }}</h3>
    <p class="api-description">
      {{ $t('compability_google_pay') }}
    </p>
    <h3 class="api-h3">{{ $t('need_connect') }}</h3>
    <p class="api-description">
     {{ $t('connect_google_pay') }}
    </p>
    <h2 class="api-title mt-4">{{ $t('sca') }}</h2>
    <p class="api-description">
      <i18n path="sca_google_pay">
        <b place="sca">SCA</b>
        <b place="3d_secure">3D-Secure</b>
      </i18n>
    </p>
    <p class="api-description">
      <i18n path="nontoken_google_pay">
        <b place="sca">SCA</b>
        <b place="3ds">3DS</b>
      </i18n>
    </p>
    <div class="api-description google-box">
      <i18n path="policy_google_pay">
        <a href="https://payments.developers.google.com/terms/aup" place="policy">{{ $t('accept_terms') }}</a>
        <a href="https://payments.developers.google.com/terms/sellertos" place="api_google_pay">{{ $t('policy_api_google_pay') }}</a>
      </i18n>
      <br />
      <b style="margin-top: 32px; display: block"
        ><i>{{ $t('trademark_google_pay') }}</i></b
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.mt-4 {
  margin-top: 32px !important;
}

.google-box {
  border-top: 0.5px solid #737373;
  padding-top: 32px !important;
}

.google-pay {
  border-top: 0.8px solid rgb(115, 115, 115);
  .api-h1 {
    padding-bottom: 20px !important;
  }
}
</style>
