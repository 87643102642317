<template>
  <div v-highlight>
    <pre class="language-javascript">
    <code>
    {{ script }}
    </code>
    </pre>
  </div>
</template>

<script>
import { ref } from "vue";
import { component as VueCodeHighlight } from "vue-code-highlight";
import "vue-code-highlight/themes/duotone-sea.css";
import "vue-code-highlight/themes/window.css";
export default {
  name: "WidgetCode",
  components: {
    VueCodeHighlight,
  },
  setup() {
    const script = ref(`
    <form action="{{ .TermUrl }}" method="post">
      <input type="hidden" name="payment_id" value="{{ .PaymentId }}" />
      <input type="hidden" name="status" value="{{ .PaymentStatus }}" />
      <input type="hidden" name="error_code" value="{{ .ErrCode }}" />
      <input type="hidden" name="confirm_res" value="{{ .ThreeDSResult }}"/>
      <input type="hidden" name="confirm_id" value="{{ .ThreeDSId }}" />
      <input type="hidden" name="confirm_otp" value="{{ .OtpResult }}" />
      <input type="hidden" name="confirm_code" value="{{ .ConfirmCode }}"/>
      <input type="hidden" name="sign" value="{{ .Sign }}" />
    </form>
    `);

    return {
      script,
    };
  },
};
</script>
