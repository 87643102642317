<template>
  <div class="api-enum">
    <h3 class="api-h3">{{ $props.title }}</h3>
    <table class="api-table">
      <caption>
        Enum description
      </caption>
      <thead>
        <tr>
          <th>Enum</th>
          <th>{{ $t("description") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in $props.list" :key="i">
          <td>{{ item.enum ? item.enum : "" }}</td>
          <td v-if="item.description" v-html="item.description"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      default: () => ({}),
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss">
.api-enum .api-table tr,
td {
  vertical-align: baseline !important;
}

.api-enum .api-table caption {
  display: none;
}
</style>
