<template>
  <div v-highlight>
    <pre class="language-javascript">
    <code v-html="codeWithTranslations"></code>
    </pre>
  </div>
</template>

<script>
import { component as VueCodeHighlight } from "vue-code-highlight";
import "vue-code-highlight/themes/duotone-sea.css";
import "vue-code-highlight/themes/window.css";
import i18n from "@/plugins/i18n";

export default {
  components: {
    VueCodeHighlight,
  },
  computed: {
    codeWithTranslations() {
      // Получаем текущий язык
      const currentLocale = this.$i18n.locale;
      
      // Определяем переводы в зависимости от языка
      const translations = {
        en: {
          shopPasswordComment: 'Shop password',
          encodeJsonComment: 'Encode the object into a JSON byte array',
          encodeBase64Comment: 'Encode JSON data in base64',
          generateSignatureComment: 'Generate signature',
          requestObjectComment: 'Request object'
        },
        ru: {
          shopPasswordComment: 'Пароль магазина',
          encodeJsonComment: 'Кодирование объекта в массив байтов JSON',
          encodeBase64Comment: 'Кодирование данных JSON в base64',
          generateSignatureComment: 'Генерация подписи',
          requestObjectComment: 'Объект запроса'
        }
      };

      // Выбираем нужный перевод
      const t = translations[currentLocale];

      return `
        &lt;?php
          $secret_key = '13148649399496d8'; // ${t.shopPasswordComment}

          $dataObject = [
              'expiration' => '2014-01-01 00:00',
              'amount' => 327.78,
              'currency' => 'KZT',
              'description' => 'proba',
              'reference' => '123456789',
          ];

          $dataJson = json_encode($dataObject); // ${t.encodeJsonComment}
          
          $data = base64_encode($dataJson); // ${t.encodeBase64Comment}
          $sign = hash_hmac('sha512', $data, $secret_key); // ${t.generateSignatureComment}

          $obj = [
            "data" => $data,
            "sign" => $sign
          ]; // ${t.requestObjectComment}
        ?&gt;
      `;
    }
  }
};
</script>
