<template>
  <div class="api-section api-widget">
    <h1 class="api-h1">{{ $t("payment_widget") }}</h1>
    <p class="api-widget__description">
      {{ $t("payment_widget_desc") }}
    </p>
    <!-- <div class="api-widget__demo">
      <span>DEMO:</span>
      <button @click="openPaymentWidgetHandler()">Открыть виджет</button>
    </div> -->
    <div class="api-title">{{ $t("widget_install") }}</div>
    <div class="api-description">
      {{ $t("widget_work_script") }}
    </div>
    <WidgetCDN class="api-widget__code" :widgetUrl="$props.widgetUrl" />
    <p class="api-widget__description">
      {{ $t("get_last_updates") }}
    </p>
    <WidgetCode
      class="api-widget__code"
      :widgetUrl="$props.widgetUrl"
      :krakendUrl="$props.krakendUrl"
    />
    <p class="api-widget__description">
      {{ $t("result_steps") }}
    </p>
    <div class="api-title">{{ $t("callback") }}</div>
    <p class="api-widget__description">
     {{ $t('success_callback') }}
    </p>
    <p class="api-widget__description">
       {{ $t('fail_callback') }}
    </p>
    <WidgetScreen v-if="image" class="api-widget__screen" />
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import WidgetCDN from "./widgetCDN.vue";
import WidgetCode from "./widgetCode.vue";
import WidgetScreen from "./widgetScreen.vue";

export default {
  props: {
    widgetUrl: {
      type: String,
      default: "",
    },
    krakendUrl: {
      type: String,
      default: "",
    },
  },
  components: {
    WidgetCDN,
    WidgetCode,
    WidgetScreen,
  },
  setup() {
    const image = ref(false);

    onMounted(() => {
      const script = document.createElement("script");
      script.src = "https://nursultan-1vision.github.io/dewpo/payment-widget.min.js";
      document.head.appendChild(script);

      if (window.location.href.includes("onevision")) {
        image.value = true;
      } else {
        image.value = false;
      }
    });

    function openPaymentWidgetHandler() {
      openPaymentWidget({
        api_key: "Ynkjnd9382n",
        publicId: "",
        amount: 1,
        currency: "KZT",
        order_id: "string",
        description: "string",
        payment_type: "pay",
        payment_method: "ecom",
        service_id: "string",
        user_id: "string",
        email: "string",
        phone: "string",
        success_url: "http://example.com",
        failure_url: "http://example.com",
        callback_url: "http://example.com",
        merchant_term_url: "http://example.com",
        payment_lifetime: 0,
        create_recurrent_profile: false,
        recurrent_profile_lifetime: 0,
        lang: "ru",
        extra_params: {},
      });
    }

    return {
      openPaymentWidgetHandler,
      image,
    };
  },
};
</script>

<style lang="scss">
.api-widget {
  width: 60%;
  padding-bottom: 40px;
  .api-h1 {
    margin-bottom: 0;
  }
  &__description {
    margin-top: 0;
    padding: 0 40px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.4em;
    font-size: 15.5px;
    color: rgb(51, 51, 51);
  }
  .api-title {
    margin-top: 50px;
  }
  &__code {
    margin: 20px 40px;
    & > pre {
      padding-bottom: 0;
    }
  }
  &__screen {
    width: 100%;
    padding: 20px 40px 0;
    object-fit: contain;
    object-position: center center;
  }
  &__demo {
    padding: 40px 40px 0px;
    display: flex;
    align-items: center;
    span {
      margin-right: 15px;
      font-family: Montserrat, sans-serif;
      font-weight: 500;
      font-size: 22px;
      color: rgb(51, 51, 51);
    }
    button {
      border: 1.5px solid #19226d;
      background: transparent;
      color: #19226d;
      font-size: 14px;
      font-weight: 500;
      border-radius: 4px;
      height: 34px;
      padding: 0 20px;
      cursor: pointer;
      transition: 0.25s;
      &:hover {
        background: #19226d;
        color: white;
      }
    }
  }
}

.onevision-widget {
  z-index: 1111111111;
}

@media (max-width: 939px) {
  .api-widget {
    width: 100%;
  }
}
</style>
