<template>
  <div v-highlight>
    <pre class="language-javascript">
      <code>
      {{ script }}
      </code>
    </pre>
  </div>
</template>

<script>
import { ref } from "vue";
import { component as VueCodeHighlight } from "vue-code-highlight";
import "vue-code-highlight/themes/duotone-sea.css";
import "vue-code-highlight/themes/window.css";
export default {
  name: "WidgetCDN",
  props: {
    widgetUrl: {
      type: String,
      default: "",
    },
  },
  components: {
    VueCodeHighlight,
  }, 
  setup(props){
    const script = ref(`<script src='${props.widgetUrl}' defer></ script>`)

    return{
      script
    }
  }
};
</script>
 
