<template>
  <div class="api-request__route">
    <button class="api-request__route-btn">
      <span class="type">
        {{ $props.requestMethod }}
      </span>
      <span class="route">
        {{ $props.requestRoute }}
      </span>
      <!-- <span class="arrow">
        <svg
          version="1.1"
          viewBox="0 0 24 24"
          x="0"
          xmlns="http://www.w3.org/2000/svg"
          y="0"
          aria-hidden="true"
          style="margin-right: -25px"
          :class="{ 'arrow-active': cardState }"
        >
          <polygon
            fill="white"
            points="17.3 8.3 12 13.6 6.7 8.3 5.3 9.7 12 16.4 18.7 9.7 "
          ></polygon>
        </svg>
      </span> -->
    </button>
    <!-- <div class="api-request__route-card" v-if="cardState">
      <div v-if="$props.testApi">
        <p>Тестовый API</p>
        <div>
          <span>{{ $props.testApi }}</span>
          {{ $props.requestRoute }}
        </div>
      </div>
      <div v-if="$props.prodApi">
        <p>Боевой API</p>
        <div>
          <span>{{ $props.prodApi }}</span>
          {{ $props.requestRoute }}
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  props: {
    requestMethod: String,
    requestRoute: String,
  },
  setup(props) {
    const cardState = ref(false);

    // const openRoutes = () => {
    //   cardState.value = !cardState.value;
    // };

    return {
      cardState,
      // openRoutes,
    };
  },
};
</script>

<style lang="scss">
.api-request {
  &__route {
    position: relative;
    &-btn {
      color: white;
      outline: 0px;
      width: 100%;
      text-align: left;
      cursor: pointer;
      padding: 10px 30px 10px 20px;
      border-radius: 4px;
      background-color: rgb(29 38 47);
      display: flex;
      white-space: nowrap;
      -webkit-box-align: center;
      align-items: center;
      border-width: 1px 1px 0px;
      border-top-style: solid;
      border-right-style: solid;
      border-left-style: solid;
      border-top-color: transparent;
      border-right-color: transparent;
      border-left-color: transparent;
      border-image: initial;
      border-bottom-style: initial;
      border-bottom-color: initial;
      transition: border-color 0.25s ease 0s;
      .type {
        color: white;
        background-color: rgb(24, 111, 175);
        padding: 5px 10px;
        text-transform: uppercase;
        font-family: "Montserrat", sans-serif;
        margin: 0px;
        font-weight: 300;
      }
      .route {
        font-family: "Courier Prime", monospace;
        font-weight: 300;
        margin-left: 10px;
        flex: 1 1 0%;
        overflow-x: hidden;
        text-overflow: ellipsis;
        color: white;
      }
      .arrow {
        svg {
          transition: 0.3s;
        }
        &-active {
          transform: rotate(180deg);
        }
      }
    }
    &-card {
      position: absolute;
      width: 100%;
      z-index: 100;
      background: rgb(250, 250, 250);
      color: rgb(38, 50, 56);
      box-sizing: border-box;
      box-shadow: rgba(0, 0, 0, 0.33) 0px 0px 6px;
      overflow: hidden;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      transition: all 0.25s ease 0s;
      visibility: visible;
      & > div {
        padding: 10px;
        p {
          margin: 0;
          font-size: 14px;
          font-weight: 400;
          color: rgb(38, 50, 56);
        }
        div {
          cursor: pointer;
          margin-top: 5px;
          padding: 7px;
          border: 1px solid rgb(204, 204, 204);
          background: rgb(255, 255, 255);
          word-break: break-all;
          color: rgb(50, 50, 159);
        }
      }
    }
  }
}
</style>
